// ProspectLeadsDashboard.jsx
import React, { useState, useEffect, useCallback } from 'react';
import ApiClient from '../auth/AuthService';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Search, Refresh, PlayArrow } from '@mui/icons-material';
import LeadInfoDialog from '../LeadInfo/LeadInfoDialog';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const ProspectLeadsDashboard = ({ user }) => {
  const [apiClient] = useState(ApiClient());
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(localStorage.getItem('startDate') || today);
  const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || today);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [action, setAction] = useState('');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLeadInfoOpen, setIsLeadInfoOpen] = useState(false);


  const fetchLeads = useCallback(async (start_date, end_date) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/prospect/', {
        params: { start_date, end_date },
      });
      console.log("API response:", response);
      if (Array.isArray(response.leads)) {
        const formattedLeads = response.leads.map(lead => ({
          ...lead,
          id: lead.id_lead,
          created_at: lead.created_at ? new Date(lead.created_at).toISOString() : '',
        }));
        setLeads(formattedLeads);
                } else {
            throw new Error('Received unexpected data format from server.');
          }
        } catch (err) {
          console.error("Error fetching leads:", err);
          setError(err.message || 'Failed to fetch leads. Please try again.');
        } finally {
          setIsLoading(false);
        }
      }, [apiClient]);


  useEffect(() => {
    fetchLeads(today, today);
  }, [fetchLeads, today]);



  const handleRowDoubleClick = async (params) => {
  const selectedRow = params.row;
  setSelectedLead(selectedRow);
  setIsLeadInfoOpen(true);
  };

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
    localStorage.setItem('startDate', date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
    localStorage.setItem('endDate', date);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSearch = () => {
    console.log("Searching with dates:", startDate, endDate);
    fetchLeads(startDate, endDate);
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    fetchLeads(today, today);
  };

  const updateLeadStatus = (leadId, newStatus, newStatusId) => {
    console.log('&&&&&&&&&& newStatus', newStatus)
    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id_lead === leadId ? { ...lead, status: newStatus, id_status: newStatusId } : lead
      )
    );
  };

  const handleAction = async () => {
    try {
      setIsLoading(true);
      await apiClient.post('/api/update_leads_status/', {
        start_date: startDate,
        end_date: endDate,
        action,
        selected_leads: selectedLeads,
      });
      await fetchLeads(startDate, endDate);
      setSelectedLeads([]);
      setAction('');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError(err.message || 'Failed to perform action. Please try again.');
      } else {
        // Обработка других ошибок
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'client_name', headerName: 'Client Name', width: 200 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      renderCell: (params) => formatDate(params.value),
    },
    { field: 'communication_history', headerName: 'Communication History', width: 300 },
    { field: 'from_number', headerName: 'SMS to Twilio', width: 150 },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            utf8WithBom: true,
            fileName: 'Leads_export.csv'
          }}
        />
      </GridToolbarContainer>
    );
  };


  return (
    <>
     <Box mt={4}>
      <Container>
        <Paper>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h5">Prospect Leads Dashboard</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                sx={{ marginRight: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                sx={{ marginRight: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<Search />}
                onClick={handleSearch}
                sx={{ marginRight: 2 }}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                startIcon={<Refresh />}
                onClick={handleReset}
                sx={{ marginRight: 2 }}
              >
                Reset
              </Button>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
                <InputLabel>Action</InputLabel>
                <Select
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                  label="Action"
                >
                  <MenuItem value="transfer_lead">Transfer to Lead status</MenuItem>
                  <MenuItem value="make_inactive">Make records inactive</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PlayArrow />}
                onClick={handleAction}
                disabled={!action || selectedLeads.length === 0}
                sx={{ marginTop: 1 }}
              >
                Apply
              </Button>
            </Box>
            {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
            {!isLoading && leads.length === 0 && (
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                No leads found for the selected date range.
              </Typography>
            )}
            {!isLoading && leads.length > 0 && (
              <DataGrid
                rows={leads}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                components={{Toolbar: CustomToolbar}}
                onRowSelectionModelChange={(newSelectionModel) => {
                setSelectedLeads(newSelectionModel);
                }}
                keepNonExistentRowsSelected
                onRowDoubleClick={handleRowDoubleClick}
                sx={{ marginTop: 2 }}
              />
            )}
            {error && (
              <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            )}

            {selectedLead && (
              <LeadInfoDialog
                open={isLeadInfoOpen}
                onClose={() => {
                  setIsLeadInfoOpen(false);
                  setSelectedLead(null);
                }}
                leadData={selectedLead}
                apiClient={apiClient}
                cUser={user}
                updateLeadStatus={updateLeadStatus}
              />
            )}
          </Box>
        </Paper>
      </Container>
     </Box>
    </>
  );
};

export default ProspectLeadsDashboard;