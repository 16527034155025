//LeadsDashboard.jsx

import React, { useState, useEffect, useCallback } from 'react';
import ApiClient from '../auth/AuthService';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
 } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Search, Refresh} from '@mui/icons-material';
import LeadInfoDialog from '../LeadInfo/LeadInfoDialog';


const LeadsDashboard = ({ user }) => {
  const [apiClient] = useState(ApiClient());
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(localStorage.getItem('startDate') || today);
  const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || today);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLeadInfoOpen, setIsLeadInfoOpen] = useState(false);


  const getOneMonthAgo = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString().split('T')[0];
  };

  const oneMonthAgo = getOneMonthAgo();

  const fetchLeads = useCallback(async (start_date, end_date) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/leads/', {
        params: { start_date, end_date },
      });
      console.log("API response:", response);
      if (Array.isArray(response.leads)) {
        const formattedLeads = response.leads.map(lead => ({
          ...lead,
          id: lead.id_lead,
          date: lead.date_last_changed ? new Date(lead.date_last_changed).toISOString() : '',
        }));
        setLeads(formattedLeads);
      } else {
        throw new Error('Received unexpected data format from server.');
      }
    } catch (err) {
      console.error("Error fetching leads:", err);
      setError(err.message || 'Failed to fetch leads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

  useEffect(() => {
    fetchLeads(today, today);
  }, [fetchLeads, today]);

   const handleRowDoubleClick = async (params) => {
      const selectedRow = params.row;

      try {
        const response = await apiClient.post(`/api/leads/update_operator/`, {
          id_lead: selectedRow.id_lead,
          username: user.username
        });

        if (response.status === 'blocked') {
          // Lead is blocked, show message to the operator
          alert(`This lead is being handled by operator: ${response.message.split(': ')[1]}`);
        } else if (response.status === 'success') {
          // Lead was successfully updated
          setSelectedLead({...selectedRow}); // Создаем новый объект
          //setSelectedLead(selectedRow);
          setIsLeadInfoOpen(true);

          setLeads((prevLeads) =>
            prevLeads.map((lead) =>
              lead.id_lead === selectedRow.id_lead
                ? {
                    ...lead,
                    fl_new_message: false,
                    operator: user.username,

                  }
                : lead
            )
          );

        }
      } catch (err) {
        console.error("Error updating lead status:", err);
        alert("An error occurred while updating the lead. Please try again.");
      }
    };

   const handleStartDateChange = (e) => {
    const date = e.target.value;
    if (date >= oneMonthAgo && date <= today) {
      setStartDate(date);
      localStorage.setItem('startDate', date);
    }
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    if (date >= oneMonthAgo && date <= today) {
      setEndDate(date);
      localStorage.setItem('endDate', date);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSearch = () => {
    console.log("Searching with dates:", startDate, endDate);
    fetchLeads(startDate, endDate);
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    fetchLeads(today, today);
  };


  const updateLeadStatus = (leadId, newStatus, newStatusId) => {
    console.log('&&&&&&&&&& newStatus', newStatus)
    setLeads((prevLeads) =>
      prevLeads.map((lead) =>
        lead.id_lead === leadId ? { ...lead, status: newStatus, id_status: newStatusId } : lead
      )
    );
  };


  const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  const columns = [
  { field: 'phone', headerName: 'Phone', width: 130 },
  { field: 'client_name', headerName: 'Client Name', width: 200 },
  { field: 'communication_history', headerName: 'Communication history', width: 250 },
  {
    field: 'date_last_changed',
    headerName: 'Date',
    width: 200,
    renderCell: (params) => formatDate(params.value),
  },
  { field: 'operator', headerName: 'Operator', width: 150 },
  { field: 'status', headerName: 'Status', width: 150 },

];

  // Функция для определения стиля строки
  const getRowClassName = (params) => {
  return params.row.fl_new_message ? 'MuiDataGrid-row highlight' : 'MuiDataGrid-row';
};



  return (
      <>
        <style>
          {`
        .highlight {
          background-color: yellow; /* Или другой цвет для выделения */
        }
      `}
        </style>

        <Box mt={4}>
          <Container>
            <Paper>
              <Box sx={{padding: 2}}>
                <Typography variant="h5">Leads Dashboard</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', marginTop: 2}}>
                  <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      sx={{marginRight: 2}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                  <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      sx={{marginRight: 2}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                  <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Search/>}
                      onClick={handleSearch}
                      sx={{marginRight: 2}}
                  >
                    Search
                  </Button>
                  <Button
                      variant="outlined"
                      startIcon={<Refresh/>}
                      onClick={handleReset}
                      sx={{marginRight: 2}}
                  >
                    Reset
                  </Button>
                </Box>
                {isLoading && <CircularProgress sx={{marginTop: 2}}/>}
                {!isLoading && leads.length === 0 && (
                    <Typography variant="body1" sx={{marginTop: 2}}>
                      No leads found for the selected date range.
                    </Typography>
                )}
                {!isLoading && leads.length > 0 && (
                    <DataGrid
                        rows={leads}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        components={{Toolbar: GridToolbar}}
                        getRowClassName={getRowClassName}
                        onRowDoubleClick={handleRowDoubleClick}

                        sx={{marginTop: 2}}
                    />
                )}
                {error && (
                    <Typography variant="body1" color="error" sx={{marginTop: 2}}>
                      {error}
                    </Typography>
                )}
                {selectedLead && (
                    <LeadInfoDialog
                        open={isLeadInfoOpen}
                        onClose={() => {
                          setIsLeadInfoOpen(false);
                          setSelectedLead(null);
                        }}
                        leadData={selectedLead}
                        apiClient={apiClient}
                        cUser={user}
                        updateLeadStatus={updateLeadStatus}
                    />
                )}
              </Box>
            </Paper>
          </Container>
        </Box>
      </>
  );
};

export default LeadsDashboard;
