// FirstCommunication.jsx
import React, { useState, useEffect, useCallback } from 'react';
import ApiClient from '../auth/AuthService';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  MenuItem,
  Divider,

} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Refresh, PlayArrow } from '@mui/icons-material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const FirstCommunication = ({ user }) => {
  const [apiClient] = useState(ApiClient());
  const today = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(localStorage.getItem('startDate') || today);
  const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || today);

  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [action, setAction] = useState('');

  const [locationZones, setLocationZones] = useState([]);
  const [selectedLocationZone, setSelectedLocationZone] = useState('');

  // Новые состояния для Max Count и Secret Code
  const [maxCount, setMaxCount] = useState('');

  const [secretCode, setSecretCode] = useState('');


  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [message, setMessage] = useState('');


  const isSecretCodeValid = () => {
    return secretCode.toLowerCase() === 'agree';
  };


  const handleSecretCodeChange = (e) => {
    setSecretCode(e.target.value);
  };

const fetchLeads = useCallback(async (start_date, end_date, location_zone, maxCount) => {
    setIsLoading(true);
    setError(null);
    setMessage(''); // Сбрасываем предыдущее сообщение
    try {
      const response = await apiClient.get('/api/first_communication/', {
        params: { start_date, end_date, location_zone, max_count: maxCount },
      });
      console.log("API response:", response);
      if (Array.isArray(response.clients)) {
        const formattedLeads = response.clients.map(lead => ({
          ...lead,
          id: lead.id_client,
          created_at: lead.created_at ? new Date(lead.created_at).toISOString() : '',
        }));
        setLeads(formattedLeads);
        if (response.message) {
          setMessage(response.message);
        }
      } else {
        throw new Error('Received unexpected data format from server.');
      }
    } catch (err) {
      console.error("Error fetching leads:", err);
      setError(err.message || 'Failed to fetch leads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);


  const fetchLocationZones = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/list_local_zone/');
      if (Array.isArray(response.location_zones)) {
        setLocationZones(response.location_zones);
      } else {
        throw new Error('Received unexpected data format from server.');
      }
    } catch (err) {
      console.error("Error fetching location zones:", err);
      setError(err.message || 'Failed to fetch location zones. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

  useEffect(() => {
   // fetchLeads(today, today);
    fetchLocationZones();
  }, [fetchLocationZones, today]);

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
    localStorage.setItem('startDate', date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
    localStorage.setItem('endDate', date);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);



  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    setSelectedLocationZone('');
    setMaxCount(''); // Очистка поля Max Count
    setSecretCode(''); // Очистка поля Secret Code
    setMessage('');
    setLeads([]); // Очищаем список лидов
  };


  const handleActionClick = () => {
    setOpenConfirmDialog(true);
  };



  const handleConfirmAction = async () => {
  setOpenConfirmDialog(false);
  try {
    setIsLoading(true);
    await fetchLeads(startDate, endDate, selectedLocationZone, maxCount);
    setAction('');
    setSecretCode('');
  } catch (err) {
    setError('An unexpected error occurred. Please try again.');
  } finally {
    setIsLoading(false);
    }
  };


const handleCloseConfirmDialog = () => {
  setOpenConfirmDialog(false);
};



  const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';

    const date = new Date(dateString);

    if (isNaN(date.getTime())) return 'Invalid Date';

    return new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(date);
  };
  const handleMaxCountChange = (e) => {
    const value = Math.min(parseInt(e.target.value) || 1, 1000);
    setMaxCount(value);
  };
  const columns = [
    { field: 'id_client', headerName: 'ID', width: 90 },
    { field: 'client_name', headerName: 'Client Name', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'created_at', headerName: 'Created At', width: 180, renderCell: (params) => formatDate(params.value), },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'city', headerName: 'City', width: 120 },
    { field: 'location_zone', headerName: 'Location Zone', width: 130 },
    { field: 'state', headerName: 'State', width: 70 },
    { field: 'zip', headerName: 'ZIP', width: 80 },
    { field: 'service', headerName: 'Service', width:150 },
     {field:'fl_active',headerName:'Active',width :80,type:'boolean'},
     {field:'fl_sent',headerName:'Sent',width :80,type:'boolean'},
   ];

   const CustomToolbar = () => (
     <GridToolbarContainer>
       <GridToolbarExport csvOptions={{ utf8WithBom: true, fileName: 'Leads_export.csv' }} />
     </GridToolbarContainer>
   );

  return (
    <>
      <Box mt={4}>
        <Container maxWidth="lg">
          <Paper elevation={3}>
            <Box sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom>First Communication Dashboard</Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  sx={{ marginRight: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  sx={{ marginRight: 2 }}
                  InputLabelProps={{ shrink: true }}
                />
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
                  <InputLabel>Location Zone</InputLabel>
                  <Select
                    value={selectedLocationZone}
                    onChange={(e) => setSelectedLocationZone(e.target.value)}
                    label="Location Zone"
                  >
                    <MenuItem value=""></MenuItem>
                    {locationZones.map((zone) => (
                      <MenuItem key={zone.location_zone} value={zone.location_zone}>
                        {zone.location_zone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  Max Count:
                </Typography>
                <TextField
                  type="number"
                  label="Max Count"
                  size="small"
                  sx={{ width: 100, marginLeft: 1 }}
                  value={maxCount}
                  onChange={handleMaxCountChange}
                  inputProps={{ min: 0, max: 3000 }}
                />


              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>

              </Box>

              <Divider sx={{ my: 3 }} />

              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
                  <InputLabel>Action</InputLabel>
                  <Select
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                    label="Action"
                  >
                    <MenuItem value="transfer_lead">Start communication</MenuItem>
                  </Select>
                </FormControl>
               <TextField
                    type="password"
                    label="Secret Code"
                    size="small"
                    sx={{ width: 150, marginRight: 2 }}
                    value={secretCode}
                    onChange={handleSecretCodeChange}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PlayArrow />}
                    onClick={handleActionClick}
                    disabled={!action || !selectedLocationZone || !isSecretCodeValid()}
                  >
                    Apply
                  </Button>

                <Dialog
                  open={openConfirmDialog}
                  onClose={handleCloseConfirmDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Confirm Action"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to proceed with the selected action? This will initiate the communication process for the selected leads.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="primary">
                      No
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary" autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>

              {isLoading && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}

              {!isLoading && leads.length === 0 && (
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  No clients found for the selected date range.
                </Typography>
              )}

              {!isLoading && leads.length > 0 && (
                <DataGrid
                  rows={leads}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  components={{ Toolbar: CustomToolbar }}
                  getRowId={(row) => row.id_client}
                  keepNonExistentRowsSelected
                  sx={{ height: 400, marginTop: 2 }}
                />
              )}
              {message && (
                <Typography variant="body1" sx={{ marginTop: 2, color: 'blue' }}>
                  {message}
                </Typography>
              )}
                {error && (
                <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                  {error}
                </Typography>
              )}

              <Divider sx={{ my: 3 }} />
              {!isLoading && leads.length > 0 && (
              <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold', color: 'green' }}>
                SMS distribution request has been sent to the server for {leads.length} clients. Estimated processing time: {Math.ceil(leads.length*2/60)} minute(s).
              </Typography>)}


            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );

};

export default FirstCommunication;
