// UploadFiles

import React, { useState } from 'react';
import ApiClient from '../auth/AuthService';
import { Button, LinearProgress, Typography, Box, Container, Paper } from '@mui/material';

const CSVUploadForm = () => {
  const [apiClient] = useState(ApiClient());
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setError('');
    } else {
      setFile(null);
      setError('Please select a valid CSV file.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError('Please select a CSV file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    setError('');

    try {
      await apiClient.post('/api/csv_upload/', formData);
      setUploadStatus('CSV file successfully uploaded');
      setUploadProgress(0);
    } catch (err) {
      setError(err.response?.data?.error || 'Error uploading CSV file');
      setUploadProgress(0);
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Upload your clients!
        </Typography>
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={handleFileChange} accept=".csv" />
          <Button
            type="submit"
            variant="contained"
            disabled={!file || isLoading}
            sx={{ mt: 2, mb: 2 }}
          >
            Upload CSV
          </Button>
          {uploadProgress > 0 && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="text.secondary">{`${uploadProgress}%`}</Typography>
            </Box>
          )}
          {uploadStatus && <Typography color="primary">{uploadStatus}</Typography>}
          {error && <Typography color="error">{error}</Typography>}
        </form>
      </Paper>
    </Container>
  );
};

export default CSVUploadForm;