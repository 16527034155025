import React, { useEffect, useState } from 'react';
import ApiClient from '../auth/AuthService';

const api = ApiClient();

function DjangoAdmin() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // Проверяем, аутентифицирован ли пользователь
                const isAuth = await api.isAuth();
                setIsAuthenticated(isAuth);

                if (isAuth) {
                    // Если аутентифицирован, делаем запрос к Django для получения URL админки
                    const response = await api.get('/api/admin-url/');
                    window.location.href = response.adminUrl;
                } else {
                    setError('User is not authenticated');
                }
            } catch (err) {
                console.error('Error accessing Django admin:', err);
                setError('Error accessing Django admin. Please try again.');
            }
        };

        checkAuth();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to access the admin panel.</div>;
    }

    return <div>Redirecting to Django Admin...</div>;
}

export default DjangoAdmin;