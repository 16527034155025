// ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ApiClient from '../auth/AuthService';

const apiClient = ApiClient();

function ProtectedRoute({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const auth = await apiClient.isAuth();
                setIsAuthenticated(auth);
            } catch (error) {
                console.error("Error checking authentication:", error);
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Можно заменить на компонент загрузки
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;