// NewClient.jsx

import React, { useState, useEffect, useCallback } from 'react';
import ApiClient from '../auth/AuthService';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Search, Refresh, PlayArrow  } from '@mui/icons-material';

import NewClientDialog from "./NewClientDialog";


const NewClients = ({ user }) => {

  const [apiClient] = useState(ApiClient());
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(localStorage.getItem('startDate') || today);
  const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || today);
  const [communications, setCommunications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [action, setAction] = useState('');
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isNewClientDialog, setIsNewClientInfoOpen] = useState(false);



  const fetchCommunications = useCallback(async (start_date, end_date) => {
    // console.log("Fetching communications with", { start_date, end_date });
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('/api/new-client/', {
        params: { start_date, end_date },
      });
      console.log("API response:", response);
      if (Array.isArray(response.communications)) {
        const formattedCommunications = response.communications.map(comm => ({
          ...comm,
          id: comm.id_communication,  // Переименование id_communication для DataGrid
          date_message: comm.date_message ? new Date(comm.date_message).toISOString() : '',
        }));
        setCommunications(formattedCommunications);
      } else {
        throw new Error('Received unexpected data format from server.');
      }
    } catch (err) {
      console.error("Error fetching communications:", err);
      setError(err.message || 'Failed to fetch communications. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

   useEffect(() => {
     fetchCommunications(today, today);
    }, [fetchCommunications, today]);


  const handleRowClick = (params) => {
    setSelectedClient(params.row);
    setIsNewClientInfoOpen(true);

  };

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
    localStorage.setItem('startDate', date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
    localStorage.setItem('endDate', date);
  };


   useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);



  const handleSearch = () => {
    console.log("Searching with dates:", startDate, endDate);
    fetchCommunications(startDate, endDate);
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    fetchCommunications(today, today);
  };

  const handleAction = async () => {
    try {
      setIsLoading(true);
      await apiClient.post('/api/update-fl-active/', {
        action,
        selected_communications: selectedClients,
      });
      await fetchCommunications(startDate, endDate);
      setSelectedClients([]);
      setAction('');
    } catch (err) {
      setError(err.message || 'Failed to perform action. Please try again.');
      }
     finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  const columns = [
    { field: 'id', headerName: 'ID Communication', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'text_message', headerName: 'Text Message', width: 300 },
    {
      field: 'date_message',
      headerName: 'Date Message',
      width: 200,
      renderCell: (params) => formatDate(params.value),
    },
    { field: 'from_number', headerName: 'From Number', width: 150 },
  ];


  return (
    <>
       <Box mt={4}>
        <Container>
          <Paper>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h5">New Clients</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  sx={{ marginRight: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  sx={{ marginRight: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Search />}
                  onClick={handleSearch}
                  sx={{ marginRight: 2 }}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={handleReset}
                  sx={{ marginRight: 2 }}
                >
                  Reset
                </Button>
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
                  <InputLabel>Action</InputLabel>
                  <Select
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                    label="Action"
                  >
                   <MenuItem value="make_inactive">Make records inactive</MenuItem>
                  </Select>
                </FormControl>
                <Button

                  variant="contained"
                  color="secondary"
                  startIcon={<PlayArrow />}
                  onClick={handleAction}
                  disabled={!action || selectedClients.length === 0}
                  sx={{ marginTop: 1 }}
                >
                  Apply
                </Button>
              </Box>
              {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
              {!isLoading && communications.length === 0 && (
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  No communications found for the selected date range.
                </Typography>
              )}
              {!isLoading && communications.length > 0 && (

                <DataGrid
                  rows={communications}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 50, page: 0 },
                    },
                  }}
                  pageSizeOptions={[50, 100]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectedClients(newSelectionModel);
                  }}
                  keepNonExistentRowsSelected
                  onRowClick={handleRowClick}
                  sx={{ marginTop: 2 }}
                />

              )}
              {error && (
                <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                  {error}
                </Typography>  )}
                  {selectedClient && (
                  <NewClientDialog
                    open={isNewClientDialog}
                    onClose={() => {
                      setIsNewClientInfoOpen(false);
                      setSelectedClient(null);
                    }}
                    communications={selectedClient}
                    apiClient={apiClient}
                    currentUser={ user }
                  />
                )}
              </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default NewClients;
