// NewClientDialog.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';

const NewClientDialog = ({ open, onClose, communications, apiClient, currentUser }) => {
  const [communicationChain, setCommunicationChain] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messageContainerRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const fetchCommunicationChain = useCallback(async () => {
    try {
      const response = await apiClient.get(`/api/get-chain_new_client/${communications.phone}/`);
      setCommunicationChain(response.communication_chain);
      setNewMessage('');
    } catch (error) {
      console.error('Error fetching communication chain:', error);
    }
  }, [apiClient, communications.phone]);


  const handleSendMessage = async () => {
    if (isSending || !newMessage.trim()) return;

    try {
      setIsSending(true);

      await apiClient.post(`/api/send-sms_new_client/`, {
        text_message: newMessage,
        phone: communications.phone,
        twilio_phone_number: communications.from_number,
        operator: currentUser.username,
      });

      setNewMessage('');
      fetchCommunicationChain();
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };


  useEffect(() => {
    if (open && communications) {
      fetchCommunicationChain();
    }
  }, [open, communications, fetchCommunicationChain]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [communicationChain]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: '800px',
          height: '600px',
        },
      }}
    >
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
        New Client Dialog
      </DialogTitle>

      <DialogContent sx={{ height: 'calc(100% - 64px - 69px)', display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ flexGrow: 1, border: 1, borderColor: 'divider', borderRadius: 1, p: 2, overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>Dialogue</Typography>
            <Box
              ref={messageContainerRef}
              sx={{
                height: 'calc(100% - 32px)',
                overflowY: 'auto',
                '& > p': { mb: 1 }
              }}
            >
              {communicationChain.map((message, index) => (
                <Typography key={index} component="p">
                  <strong>
                    {message.message_type === 'Received' ? `Client:` : `${message.message_type}:`}
                  </strong>
                  {` ${message.text_message}`}
                </Typography>
              ))}
            </Box>
          </Box>

          <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, p: 2 }}>
            <Typography variant="h6" gutterBottom>
              New message from: <Box component="span" sx={{ fontWeight: 'normal' }}>{communications.from_number}</Box>
            </Typography>

            <TextField
              id="message-text"
              fullWidth
              multiline
              rows={3}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value.slice(0, 150))}
              placeholder="Type your message (max 150 characters)"
              inputProps={{ maxLength: 150 }}
              helperText={`${newMessage.length}/150`}
              sx={{ mt: 1 }}
            />
          </Box>
        </Box>
      </DialogContent>

      <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 0, p: 2 }}>
        <DialogActions sx={{ p: 1, gap: 0.5 }}>
          <Button
            id="send-button"
            onClick={handleSendMessage}
            variant="contained"
            color="primary"
            disabled={isSending || !newMessage.trim()}
          >
            {isSending ? 'Sending...' : 'Send'}
          </Button>
          <Button variant="outlined" onClick={fetchCommunicationChain}>Refresh</Button>
          <Button variant="outlined" onClick={onClose}>Close</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NewClientDialog;