// AuthService.js
import axios from 'axios';
import { APP_VERSION } from '../config';

const AUTH_URL = process.env.REACT_APP_API;
console.log("API URL:", AUTH_URL);

const ApiClient = () => {
    console.log("ApiClient function called");
    const api = axios.create({
        baseURL: AUTH_URL,
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        }
    });

    api.interceptors.request.use(
        (config) => {
           const token = sessionStorage.getItem(`access_token_${APP_VERSION}`);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = sessionStorage.getItem(`refresh_token_${APP_VERSION}`);

                if (refreshToken) {
                    try {
                        const response = await api.post("/auth/token/refresh/", { refresh: refreshToken });
                        console.log("Token refresh response:", response);

                        sessionStorage.setItem(`access_token_${APP_VERSION}`, response.data.access);
                        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

                        return api(originalRequest);
                    } catch (refreshError) {
                        console.log("Refresh token is invalid", refreshError);
                        logout();
                        return Promise.reject(refreshError);
                    }
                } else {
                    logout();
                }
            }

            return Promise.reject(error);
        }
    );

    const login = async (username, password) => {
        try {
            const response = await api.post("/api/login/", { username, password });
            console.log("!!!!Login response received:", response.data);

            if (response.data && response.data.access && response.data.refresh && response.data.user) {
                sessionStorage.setItem(`access_token_${APP_VERSION}`, response.data.access);
                sessionStorage.setItem(`refresh_token_${APP_VERSION}`, response.data.refresh);
                sessionStorage.setItem(`user_${APP_VERSION}`, JSON.stringify(response.data.user));

                return response.data.user;
            } else {
                console.error("Invalid response format:", response.data);
                throw new Error("Invalid response format");
            }
        } catch (error) {
            console.error("Login error:", error.response || error.message);
            throw error;
        }
    };

    const get = async (path, params = {}) => {
        try {
            const response = await api.get(path, { params });
            return response.data;
        } catch (error) {
            console.error(`GET request to ${path} failed`, error);
            throw error;
        }
    };

    const post = async (path, data, config = {}) => {
        try {
            const mergedConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                },
                ...config
            };

            if (data instanceof FormData) {
                delete mergedConfig.headers['Content-Type'];
                mergedConfig.headers['Content-Type'] = 'multipart/form-data';
                mergedConfig.transformRequest = [function (data, headers) {
                    return data;
                }];
            }

            const response = await api.post(path, data, mergedConfig);
            return response.data;
        } catch (error) {
            console.error(`POST request to ${path} failed`, error);
            throw error;
        }
    };

    const put = async (path, data) => {
        try {
            const response = await api.put(path, data);
            return response.data;
        } catch (error) {
            console.error(`PUT request to ${path} failed`, error);
            throw error;
        }
    };

    const del = async (path) => {
        try {
            const response = await api.delete(path);
            return response;
        } catch (error) {
            console.error(`DELETE request to ${path} failed`, error);
            throw error;
        }
    };

    const isAuth = () => {
        try {
            const token = sessionStorage.getItem(`access_token_${APP_VERSION}`);
            console.log('Token retrieved from sessionStorage:', token);
            const isAuthenticated = !!token;
            console.log('User is authenticated:', isAuthenticated);
            return isAuthenticated;
        } catch (error) {
            console.error('Error checking authentication status:', error);
            return false;
        }
    };

    const logout = () => {
        console.log('Logging out');
        sessionStorage.removeItem(`access_token_${APP_VERSION}`);
        sessionStorage.removeItem(`refresh_token_${APP_VERSION}`);
        sessionStorage.removeItem(`user_${APP_VERSION}`);
        window.location.href = '/login';
    };

    const getUser = () => {
        console.log('getUser function called');
        const userString = sessionStorage.getItem(`user_${APP_VERSION}`);
        console.log('AAAAA User string from sessionStorage:', userString);
        return userString ? JSON.parse(userString) : null;
    };

    const baseURL = () => {
        return api.defaults.baseURL;
    };

    return {
        login,
        get,
        post,
        put,
        del,
        isAuth,
        logout,
        baseURL,
        getUser
    };
};

export default ApiClient;