// Report.jsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ApiClient from '../auth/AuthService';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel, Divider,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Search, Refresh } from '@mui/icons-material';



const Report = ({user}) => {

  const [apiClient] = useState(ApiClient());
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState( today);
  const [endDate, setEndDate] = useState(today);
  const [allLeads, setAllLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    new: 0,
    followup: 0,
    booked: 0,
    refused: 0,
  });
  const [bookingRate, setBookingRate] = useState(0);
  const [answerRate, setAnswerRate] = useState(0);
  const [statusFilter, setStatusFilter] = useState('all');

const fetchLeads = useCallback(async (start_date, end_date) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiClient.get('api/reports/', {
        params: { start_date, end_date },
      });

      if (
        response &&
        Array.isArray(response.leads) &&
        response.df_new_count !== undefined &&
        response.df_followup_count !== undefined &&
        response.df_booked_count !== undefined &&
        response.df_refused_count !== undefined &&
        response.booking_rate !== undefined &&
        response.answer_rate !== undefined
      ) {
        const formattedLeads = response.leads.map(lead => ({
          ...lead,
          id: lead.id_lead,
          date: lead.created_at ? new Date(lead.created_at).toISOString() : '',
        }));
        setAllLeads(formattedLeads);

        setStats({
          new: response.df_new_count,
          followup: response.df_followup_count,
          booked: response.df_booked_count,
          refused: response.df_refused_count,
        });
        setBookingRate(response.booking_rate);
        setAnswerRate(response.answer_rate);
      } else {
        throw new Error('Received unexpected data format from server.');
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch leads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [apiClient]);

  useEffect(() => {
    fetchLeads(today, today);
  }, [fetchLeads, today]);


   const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
    localStorage.setItem('startDate', date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
    localStorage.setItem('endDate', date);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSearch = () => {
    console.log("Searching with dates:", startDate, endDate);
    fetchLeads(startDate, endDate);

  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    setStatusFilter('all');
    fetchLeads(today, today);

  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredLeads = useMemo(() => {
    if (statusFilter === 'all') {
      return allLeads;
    }
    return allLeads.filter(lead => lead.status.toLowerCase() === statusFilter);
  }, [allLeads, statusFilter]);

  const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  const columns = [
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'client_name', headerName: 'Client Name', width: 200 },
    { field: 'communication_history', headerName: 'Communication history', width: 250 },
    {
      field: 'created_at',
      headerName: 'Date',
      width: 200,
      renderCell: (params) => formatDate(params.value),
    },
    { field: 'operator', headerName: 'Operator', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  return (
    <>
      <Box mt={4}>
        <Container>
          <Paper>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h5">Leads Report</Typography>

              {/* Stats Section */}
             <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 2 }}>
              {Object.entries(stats).map(([key, value]) => (
                <Grid item xs={6} sm={3} key={key}>
                  <Paper elevation={3} sx={{ padding: 1, textAlign: 'center' }}>
                    <Typography variant="subtitle1">{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                    <Typography variant="h6">{value} LEADS</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>

              {/* Warning Section */}
              <Box sx={{ width: '100%',display: 'flex', justifyContent: 'center', marginBottom: 2 }}>

                <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff9c4', maxWidth: '600px', width: '100%' }}>
                  <Typography variant="body1" align="center">
                    Dear {user.username}! Your Booking rate <strong>{bookingRate}%</strong> and AnswerRate at <strong>{answerRate}%</strong>
                  </Typography>
                </Paper>
              </Box>
              <Divider sx={{ my: 2 }} />

              {/* Date Inputs and Buttons */}
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'center', marginTop: 2, width: '100%' }}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                sx={{ margin: { xs: '0 0 1rem 0', sm: '0 1rem 0 0' }, width: { xs: '100%', sm: 'auto' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                sx={{ margin: { xs: '0 0 1rem 0', sm: '0 1rem 0 0' }, width: { xs: '100%', sm: 'auto' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<Search />}
                onClick={handleSearch}
                sx={{ margin: { xs: '0 0 1rem 0', sm: '0 1rem 0 0' }, width: { xs: '100%', sm: 'auto' } }}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                startIcon={<Refresh />}
                onClick={handleReset}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                Reset
              </Button>
            </Box>

              <Divider sx={{ my: 2 }} />

              {/* Status Filter */}
             <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 2 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ textAlign: 'center', fontSize: '1.2rem' }}>Status Filter</FormLabel>
                <RadioGroup
                  row
                  aria-label="status-filter"
                  name="status-filter"
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  sx={{ justifyContent: 'center' }}
                >
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="new" control={<Radio />} label="New" />
                  <FormControlLabel value="followup" control={<Radio />} label="FollowUp" />
                  <FormControlLabel value="booked" control={<Radio />} label="Booked" />
                  <FormControlLabel value="refused" control={<Radio />} label="Refused" />
                </RadioGroup>
              </FormControl>
            </Box>

              {isLoading && <CircularProgress sx={{ marginTop: 2 }} />}
              {!isLoading && filteredLeads.length === 0 && (
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  No leads found for the selected criteria.
                </Typography>
              )}
              {!isLoading && filteredLeads.length > 0 && (
                <DataGrid
                  rows={filteredLeads}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 50, page: 0 },
                    },
                  }}
                  pageSizeOptions={[50, 100, 200]}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: false,
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: true },
                      excelOptions: {
                        fileName: 'LeadsReport',
                        disableToolbarButton: true
                      },
                    },
                  }}
                  disableColumnFilter
                  sx={{ marginTop: 2 }}
                />
              )}
              {error && (
                <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Report;